/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import Banner from '../../../components/Banner';
import Video from '../../../components/Video';
import CustomBannerInsert from '../../../components/CustomBannerInsert';
import Testimonials from '../../../components/Testimonials';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
// ASSETS
import BackgroundImage1 from '../../../assets/img/BackgroundImage_2.png';
import LogoAlt from '../../../assets/img/logo-alt.svg';
// HELPERS
import constants from '../../../constants/constants';

const HomePage = (props) => {
  const { dynamicPageModel, buttonAction, history } = props;
  const [isLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [offset, setOffset] = useState(0);
  // VIDEO COMMERCIAL
  const VideoSource = `${constants.API_URL}Attachments/Videos/pts-pal-preschool-commercial.mp4`;
  const VideoPoster = `${constants.API_URL}Attachments/Videos/pts-pal-preschool-commercial-poster.png`;
  // OTHER
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const parallaxContainers = document.querySelectorAll('.parallax-container');

    const scrollHandler = () => {
      parallaxContainers.forEach((container) => {
        handleParallax(container);
      });
    };

    window.addEventListener('scroll', scrollHandler);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const handleParallax = (element, speed = 0.1) => {
    const yOffset = window.pageYOffset;
    const parallaxSpeed = yOffset * speed;
    element.style.backgroundPositionY = `calc(20% - ${parallaxSpeed}px)`;
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      {/* BANNER SECTION */}
      <CustomBlock className="banner-container">
        <Slider {...bannerSliderSettings}>
          {/* CUSTOM BANNER INSERT */}
          <CustomBannerInsert offsetValue={offset} />
          {
            banners.map((banner) => (
              <Banner
                isDynamicBanner
                isTitleUppercase
                isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                title={banner.title}
                imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                description={banner.description}
                primaryActionText={banner.primaryButtonText}
                primaryActionOnClick={() => {
                  buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
                }}
                secondaryActionText={banner.secondaryButtonText}
                secondaryActionOnClick={() => {
                  buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                }}
                offset={offset}
              />
            ))
          }
        </Slider>
      </CustomBlock>

      {/* CONTENT SECTION - WEBSITE INTRO */}
      <CustomBlock className="content-section">
        <CustomBlock className="website-intro">
          <Video
            containerClassName="container-1"
            src={VideoSource}
            poster={VideoPoster}
            isAutoplay={false}
          />
          <CustomBlock className="container-2">
            <Image source={LogoAlt} className="logo mb-20" />
            <p className="light--clr size-mdlg">
              Explore comprehensive child development services at Pediatric Therapy Solutions, where every child&apos;s potential is our passion.
            </p>
            <Button
              type="button"
              round-corners
              text="Visit Pediatric Therapy Solutions"
              onClick={() => navigateToLink('https://ptsprairieville.com/')}
              className="secondary--sbg mt-40"
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* CONTENT SECTION - ABOUT INTRO */}
      <CustomBlock className="content-section mt-60 mb-30">
        <CustomBlock className="content-section__absolute-background" style={{ backgroundImage: `url(${BackgroundImage1})` }} />
        <CustomBlock className="about-intro">
          <h3 className="about-intro__header light--clr pb-20">Embracing Every Step of Growth</h3>
          <p className="about-intro__description lightest--clr size-mdlg">PAL Preschool, a core part of Pediatric Therapy Solutions, is dedicated to nurturing your child&apos;s holistic development in a collaborative educational and therapeutic environment.</p>
          <Button
            round-corners
            text="About PAL Preschool"
            to="/about-pts-preschool"
            className="secondary--sbg mt-40"
          />
        </CustomBlock>
      </CustomBlock>

      {/* CONTENT SECTION - TESTIMONIALS */}
      <CustomBlock className="content-section">
        <CustomBlock className="testimonials-section">
          <CustomBlock className="testimonials-section__intro mb-60">
            <h3 className="about-intro__header secondary--clr">Real Stories, Real Progress</h3>
            <hr className="secondary-lighter--sbg" />
            <p className="mid-grey--clr size-mdlg">
              Hear how PAL Preschool makes a difference.
            </p>
          </CustomBlock>
          <Testimonials />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default HomePage;