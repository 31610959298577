// DEPENDENCIES
import React from 'react';
// ICONS
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import { FaStar } from 'react-icons/fa';
// COMPONENTS
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
// HELPERS
import testimonialsData from '../constants/testimonialsData';

const Testimonials = () => {
  const testimonials = testimonialsData;

  return (
    <CustomBlock className="testimonials-container">
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 1000: 2, 1200: 3 }}
        className="testimonials"
      >
        <Masonry gutter="30px">
          {testimonials.map((testimonial) => (
            <CustomBlock className="testimonial">
              <RiDoubleQuotesL className="quote-icon quote-icon__start" />
              <blockquote>
                <p className="dark-grey--clr size-sm">
                  {testimonial.content}
                  <RiDoubleQuotesR className="quote-icon quote-icon__end" />
                </p>
              </blockquote>
              <hr />
              <footer>
                <p className="mid-grey--clr size-sm">
                  {testimonial.author}
                </p>
                <CustomBlock className="stars-wrapper">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </CustomBlock>
              </footer>
            </CustomBlock>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </CustomBlock>
  );
};

export default Testimonials;