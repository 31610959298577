/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// ICONS
import {
  MdEmail,
  MdLocalPhone,
  MdLocationPin,
} from 'react-icons/md';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import Image from '../../../components/Image';
import Testimonials from '../../../components/Testimonials';
// ASSETS - CONTACT PAGE
import CompanyLogo from '../../../assets/img/logo.svg';
import BannerImage from '../../../assets/img/banner-image_default.png';
import BackgroundDecorationImage from '../../../assets/img/background-decoration_dark-light-waves.svg';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';
import getSettingByKey from '../../../services/management/settingService';

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapURL, setGoogleMapURL] = useState([]);
  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  useEffect(() => {
    fetchGoogleMapURL('GoogleMapURL');
  }, []);

  const fetchGoogleMapURL = (key) => {
    setIsLoading(true);
    getSettingByKey(key).then((res) => {
      setGoogleMapURL(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact-pts-preschool');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock className="contact-page">
        {isLoading && <Overlay hasLoader />}
        <Banner
          isDynamicBanner
          isTitleUppercase
          title="Get In Touch"
          imageSource={BannerImage}
        />

        <CustomBlock className="content-container--padded">
          {/* COMPANY LOGO SECTION */}
          <CustomBlock className="company-logo-section">
            <CustomBlock className="custom-content-section">
              <Image
                containerClassName="logo-container"
                className="logo"
                source={CompanyLogo}
              />
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="custom-content-section flex-container">

            {/* COMPANY DETAILS SECTION */}
            <CustomBlock className="details-section">
              <h2 className="fw-800 pb-5">Contact</h2>
              <CustomBlock className="contact">
                <CustomBlock className="contact__row">
                  <CustomBlock className="contact__row__icon">
                    <MdEmail size="18" className="white--clr" />
                  </CustomBlock>
                  <p>{cmsInfo.emailAddress}</p>
                </CustomBlock>

                <CustomBlock className="contact__row">
                  <CustomBlock className="contact__row__icon">
                    <MdLocalPhone size="18" className="white--clr" />
                  </CustomBlock>
                  <p>{cmsInfo.phoneNumber}</p>
                </CustomBlock>
              </CustomBlock>

              <CustomBlock className="location mt-30">
                <h2 className="fw-800 pb-5">Location</h2>
                <CustomBlock className="contact__row">
                  <CustomBlock className="contact__row__icon">
                    <MdLocationPin size="18" className="white--clr" />
                  </CustomBlock>
                  <p>
                    {cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}
                    <br />
                    {cmsInfo.city}, {cmsInfo.state}, {cmsInfo.zipCode}
                  </p>
                </CustomBlock>
              </CustomBlock>

              {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                <CustomBlock className="hours mt-30">
                  <h2 className="fw-800">Hours of Operation</h2>
                  <p>
                    {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                      <CustomBlock className="mt-5 mb-5">
                        {cmsInfo.primaryWeekDayHours && `${cmsInfo.primaryWeekDayHours}`}
                        <br />
                        {cmsInfo.secondaryWeekDayHours && `${cmsInfo.secondaryWeekDayHours}`}
                      </CustomBlock>
                    )}

                  </p>
                  {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                    <CustomBlock>
                      {cmsInfo.primaryWeekEndHours && (
                        <p>{cmsInfo.primaryWeekEndHours}</p>
                      )}
                      {cmsInfo.secondaryWeekEndHours && (
                        <p>{cmsInfo.secondaryWeekEndHours}</p>
                      )}
                    </CustomBlock>
                  )}
                </CustomBlock>
              )}
            </CustomBlock>

            {/* GOOGLE MAPS SECTION */}
            {googleMapURL.length > 0 && (
              <CustomBlock className="google-map-section">
                <iframe
                  title="Company Google Map"
                  frameBorder="0"
                  style={{ border: '0 !important' }}
                  src={googleMapURL}
                  allowFullScreen
                />
              </CustomBlock>
            )}
          </CustomBlock>

          <CustomBlock className="responsive-content-section pb-20">
            <Image
              containerClassName="background-decoration-container"
              className="background-decoration"
              source={BackgroundDecorationImage}
            />
          </CustomBlock>

          <CustomBlock
            id="contactForm"
            className="content-section"
            style={{ position: 'relative', zIndex: '2' }}
          >
            <CustomBlock className="content-container--card-style--with-shadow contact-form">
              <ContactForm
                formConfig={formConfig}
                onSubmit={(values) => submitForm(values)}
                title={<p className="secondary--clr size-lg"><span className="custom-header--primary-light primary--clr fw-700">Questions?</span> We Have Answers</p>}
              />
            </CustomBlock>
          </CustomBlock>

          {/* CONTENT SECTION - TESTIMONIALS */}
          <CustomBlock className="content-section pt-0">
            <CustomBlock className="testimonials-section">
              <CustomBlock className="testimonials-section__intro mb-60">
                <h3 className="about-intro__header secondary--clr">Real Stories, Real Progress</h3>
                <hr className="secondary-lighter--sbg" />
                <p className="mid-grey--clr size-mdlg">
                  Hear how PAL Preschool makes a difference.
                </p>
              </CustomBlock>
              <Testimonials />
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);