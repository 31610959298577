const testimonialsData = [
  {
    content: 'PAL Preschool was the best thing we ever did! Such an amazing program.',
    author: 'A. R.',
  },
  {
    content: 'PAL Preschool is such a great program. My grandson has made amazing strides. And he loves it!',
    author: 'A. B.',
  },
  {
    content: 'I’ll be forever grateful for PTS and the PAL Preschool program. Our daughter thrived in the program after being delayed a good bit in feeding, walking, and many other delays.',
    author: 'C. M.',
  },
];

export default testimonialsData;