/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
import { Route } from 'react-router-dom';
// ICONS
// CUSTOM COMPONENTS;
import Overlay from '../components/Overlay';
import CustomBlock from '../components/CustomBlock';
import HeaderNavigation from '../components/HeaderNavigation';
import FooterBlock from '../components/FooterBlock';
// ASSETS
import Logo from '../assets/img/logo.svg';

const CMSRoute = (cmsRouteProps) => {
  const {
    component: Component,
    cmsInfo, dynamicPageId,
    isBlog,
    ...rest
  } = cmsRouteProps;

  const { companyLogoPath, companyIconPath } = cmsInfo;
  const [isLoading] = useState(false);

  return (
    <Route
      {...rest} render={(props) => (
        <>
          {isLoading && <Overlay hasLoader />}
          <HeaderNavigation
            // hasSearchBlock
            isSubmenuFullWidth
            cmsInfo={cmsInfo}
            icon={companyIconPath}
            logo={companyLogoPath}
            // logoAlt={Logo}
            menuItems={cmsInfo.menuItems}
            hasSubmenu
            destination="/page"
            iconAppearance="circle"
            backgroundColor="transparent"
            menuItemsBlockBackgroundColor="primary--sbg"
            menuItemColor="primary--clr"
            submenuItemColor="primary-lighter--clr"
            menuToggleIconColor="primary--clr"
            primarySocialMediaLink={cmsInfo.facebookLink}
            phoneNumber={cmsInfo.phoneNumber}
            emailAddress={cmsInfo.emailAddress}
            secondarySocialMediaLink={cmsInfo.instagramLink}
            stores={cmsInfo.stores}
            categories={cmsInfo.categories}
            featuredSections={cmsInfo.featuredSections}
            {...props}
          />

          <CustomBlock className="cms-container lightest-grey--sbg">
            <Component
              {...props}
              dynamicPageId={dynamicPageId}
              cmsInfo={cmsInfo}
              isBlog={isBlog}
            />
          </CustomBlock>

          <FooterBlock
            // hasEmailSignUp
            to="/"
            cmsInfo={cmsInfo}
            imageSource={companyLogoPath}
            companyName={cmsInfo.companyName}
            phoneNumber={cmsInfo.phoneNumber}
            emailAddress={cmsInfo.emailAddress}
            // backgroundColor="primary--sbg"
            fixedNavigationBarBackgroundColor="primary--sbg"
            footerHeaderTextColor="white--clr"
            copyrightTextColor="white--clr"
            bottomFooterBackgroundColor="primary-lighter--sbg"
            iconAppearance="circle"
            socialIconBackgroundColor="primary-lighter--sbg"
            socialIconColor="white--clr"
            footerItems={cmsInfo.footerItems}
            appStoreLink={cmsInfo.appStoreLink}
            googlePlayLink={cmsInfo.googlePlayStoreLink}
            facebookLink={cmsInfo.facebookLink}
            instagramLink={cmsInfo.instagramLink}
            twitterLink={cmsInfo.twitterLink}
            linkedInLink={cmsInfo.linkedInLink}
            youtubeLink={cmsInfo.youtubeLink}
            tiktokLink={cmsInfo.tikTokLink}
            featuredSections={cmsInfo.featuredSections}
            {...props}
          />
        </>
      )}
    />
  );
};

export default CMSRoute;