/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Email/';

const sendEmail = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SendEmail`, model, constants.REQUIRE_INTERCEPTORS);
const sendEmailWithTemplate = () => console.log('New Feature');

export {
  sendEmail,
  sendEmailWithTemplate,
};