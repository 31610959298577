// DEPENDENCIES
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const Video = (props) => {
  const {
    src,
    poster,
    className = '',
    containerClassName = 'video-container',
    isAutoplay = false,
    hasControls = false,
    isLoop = false,
    isMuted = false,
    captionSrc = '',
    captionLang = 'en',
    captionLabel = 'English',
  } = props;
  const videoRef = useRef(null);

  return (
    <CustomBlock className={containerClassName}>
      <video
        ref={videoRef}
        src={src}
        poster={poster}
        className={`${className} video-player`}
        autoPlay={isAutoplay}
        controls={hasControls}
        loop={isLoop}
        muted={isMuted}
      >
        <track
          src={captionSrc}
          kind="captions"
          srcLang={captionLang}
          label={captionLabel}
          default
        />
      </video>
    </CustomBlock>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isAutoplay: PropTypes.bool,
  hasControls: PropTypes.bool,
  isLoop: PropTypes.bool,
  isMuted: PropTypes.bool,
  captionSrc: PropTypes.string,
  captionLang: PropTypes.string,
  captionLabel: PropTypes.string,
};

Video.defaultProps = {
  className: '',
  containerClassName: 'video-container',
  isAutoplay: false,
  hasControls: true,
  isLoop: false,
  isMuted: false,
  captionSrc: '',
  captionLang: 'en',
  captionLabel: 'English',
  poster: '',
};

export default Video;