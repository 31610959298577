// DEPENDENCIES
import React from 'react';
// COMPONENTS
import Slider from 'react-slick';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
// ASSETS
import IllustrationHomepageHero from '../assets/img/Illustration_HomepageHero.svg';
import IllustrationHandPrint from '../assets/img/Illustration_HandPrint.svg';
import BannerImage from '../assets/img/homepage-banner-image_default.png';
// HELPERS
import constants from '../constants/constants';

const CustomDescription = () => (
  <CustomBlock className="custom-description">
    <CustomBlock>
      <img
        src={IllustrationHandPrint}
        alt="llustration"
      />
      <p className="dark-grey--clr custom-banner-description">Foundations for Lifelong Learning</p>
    </CustomBlock>
    <CustomBlock>
      <img
        src={IllustrationHandPrint}
        alt="llustration"
      />
      <p className="dark-grey--clr custom-banner-description">Personalized Play and Therapy</p>
    </CustomBlock>
    <CustomBlock>
      <img
        src={IllustrationHandPrint}
        alt="llustration"
      />
      <p className="dark-grey--clr custom-banner-description">A Community Celebrating Every Child</p>
    </CustomBlock>
  </CustomBlock>
);

const RotatingImageSlider = () => {
  const rotatingImages = Array.from({ length: 6 }, (_, i) => `${constants.API_URL}Attachments/RotatingImages/image-${i + 1}.png`);

  const customPagingElement = () => (
    <a href="#!">
      <img src={IllustrationHandPrint} alt="Dot" />
    </a>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: customPagingElement,
    dotsClass: 'slick-dots custom-dots'
  };

  return (
    <Slider
      {...settings}
      className="custom-banner__slider"
    >
      {rotatingImages.map((imageUrl, index) => (
        <img
          src={imageUrl}
          alt={`Slide ${index}`}
          className="slick-image"
        />
      ))}
    </Slider>
  );
};

const CustomBannerInsert = (props) => {
  const { offsetValue } = props;

  return (
    <CustomBlock
      className="custom-banner"
      style={{
        backgroundImage: `url(${BannerImage})`,
        backgroundPositionY: `${offsetValue * 0.25}px`
      }}
    >
      <CustomBlock className="custom-banner__primary">
        <img
          src={IllustrationHomepageHero}
          alt="llustration"
          style={{ minWidth: '100%', maxWidth: '600px' }}
          className="main-img pb-30"
        />
        <CustomDescription />
        <Button
          text="Start Your Child's Journey"
          to="/about-pts-preschool"
          className="secondary--sbg fw-600 mt-40"
        />
      </CustomBlock>

      <CustomBlock className="custom-banner__secondary">
        <RotatingImageSlider />
      </CustomBlock>
    </CustomBlock>
  );
};

export default CustomBannerInsert;